<template>
  <div>
    <div>
      <h2 class="font-weight-bold black--text">
        Siswa/i yang mengikuti ujian
      </h2>
      <span>Daftar semua siswa/i yang telah mengikuti ujian</span>
    </div>
    <v-row class="my-6">
      <v-col
        md="8"
        sm="12"
      >
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <div>
              <v-text-field
                label="Cari"
                outlined
                dense
                hide-details="none"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <div>
              <v-autocomplete
                label="Semua"
                outlined
                dense
                hide-details="none"
              ></v-autocomplete>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <div class="d-md-flex justify-md-end">
          <v-btn
            color="primary"
            large
            width="100%"
            @click="remindBtn"
          >
            <v-icon class="mr-2">
              {{ icons.mdiBellOutline }}
            </v-icon> Ingatkan Semua
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card
      v-for="(student, index) in exam.student"
      :key="student.uuid"
      outlined
      class="pa-4 mb-2 overflow-x-auto"
    >
      <v-row class="align-center">
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          cols="12"
        >
          <div
            class="d-flex justify-start align-center"
          >
            <div>
              <span class="d-inline-block mr-4">{{ index + 1 }}</span>
            </div>
            <div>
              <v-avatar>
                <v-img
                  v-if="student.photo"
                  :src="student.photo === undefined ? photo : student.photo "
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/images/avatars/ari.png"
                ></v-img>
              </v-avatar>
            </div>
            <div>
              <span class="d-inline-block pl-4">{{ student.name }}</span>
            </div>
          </div>
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="6"
          cols="12"
        >
          <v-chip
            class="text-subtitle-2 text-md-caption text-lg-caption text-xl-caption"
            :color="getColorChip(student.status)"
          >
            {{ student.status }}
          </v-chip>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          cols="12"
        >
          <div class="d-flex align-center">
            <div>
              <v-img
                src="@/assets/icons/calendar.svg"
                width="20"
              ></v-img>
            </div>
            <span class="pl-2 text-subtitle-2">{{ exam.deadline === undefined ? '-' : exam.deadline }}</span>
          </div>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
          cols="12"
        >
          <v-btn
            :to="{ name: 'elearning-teacher-section-chapter-result-detail-exam',params: {uuid : $route.params.uuid,uuidStudent: student.uuid}}"
            width="100%"
            :color="colorButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.isRemind === undefined ? 0 : 1)"
          >
            <v-icon
              v-show="isHide"
              class="mr-2"
              :class="iconButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1)"
            >
              {{ iconButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1) }}
            </v-icon>
            {{ textButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1) }}
          </v-btn>
          <!-- <v-btn
            width="100%"
            :color="colorButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.isRemind === undefined ? 0 : 1)"
          >
            <router-link>
            <v-icon
              v-show="isHide"
              class="mr-2"
              :class="iconButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1)"
            >
              {{ iconButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1) }}
            </v-icon>
            {{ textButton(student.status === null ? 0 : 1, parseInt(student.is_correct), student.is_remind === undefined ? 0 : 1) }}
            </router-link>
          </v-btn> -->
        </v-col>
      </v-row>
    </v-card>
    <v-skeleton-loader
      v-for="i,index in 6"
      v-show="!exam.student"
      :key="index"
      type="image"
      height="80"
      class="my-2"
    >
    </v-skeleton-loader>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      text-button="Ya"
      header="Ingatkan Semua Siswa/I"
      @close-button="modalDialog = false"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        Apakah anda ingin mengingatkan semua siswa/i yang belum mengumpulkan latihan ?
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import thumbnail from '@/assets/images/avatars/ari.png'
import ModalDialog from '@/views/components/ModalDialog.vue'
import { mdiBellCheckOutline, mdiBellOutline } from '@mdi/js'

export default {
  name: 'ElearningSubjectResultTable',
  components: {
    ModalDialog,
  },
  data() {
    return {
      modalDialog: false,
      width: 500,
      isHide: true,
      icons: {
        mdiBellOutline,
        mdiBellCheckOutline,
      },
      thumbnail,
      exam: {},
    }
  },
  mounted() {
    this.getExam(this.$route.params.uuid)
  },
  methods: {
    async getExam(uuid) {
      await this.$services.ApiServices.get('exam', uuid).then(({ data }) => {
        this.exam = data.data
      })
    },
    colorButton(status, isCorrect, isRemind) {
      let colorBtn = ''
      switch (status) {
        case 1:
          if (isCorrect === 1 && isRemind === 1) colorBtn = 'v-btn--outlined theme--light primary--text'
          if (isCorrect === 0 && isRemind === 1) colorBtn = 'primary plain--text'
          if (isCorrect === 0 && isRemind === 0) colorBtn = 'primary plain--text'
          break
        case 0:
          if (isCorrect === 0 && isRemind === 1) colorBtn = 'v-btn v-btn--text theme--light success--text shadow-none'
          if (isCorrect === 0 && isRemind === 0) colorBtn = 'v-btn v-btn--text theme--light primary--text shadow-none'
          break
        default:
          colorBtn = ''
          break
      }

      return colorBtn

      // if (status === 1) {
      //   if (isCorrect === 1 && isRemind === 1) {
      //     return 'v-btn--outlined theme--light primary--text'
      //   }
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return 'primary plain--text'
      //   }
      // } else {
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return 'v-btn v-btn--text theme--light success--text shadow-none'
      //   }
      //   if (isCorrect === 0 && isRemind === 0) {
      //     return 'v-btn v-btn--text theme--light primary--text shadow-none'
      //   }
      // }

      // return 'primary'
    },
    textButton(status, isCorrect, isRemind) {
      let textBtn = ''
      switch (status) {
        case 1:
          if (isCorrect === 1 && isRemind === 1) textBtn = 'Sudah Dikoreksi'
          if ((isCorrect === 0 && isRemind === 1) || (isCorrect === 0 && isRemind === 0)) textBtn = 'Koreksi'
          break
        case 0:
          if (isCorrect === 0 && isRemind === 1) textBtn = 'Sudah Diingatkan'
          if (isCorrect === 0 && isRemind === 0) textBtn = 'Ingatkan'
          break
        default:
          textBtn = 'Belum dikoreksi'
          break
      }

      return textBtn
      // if (status === 1) {
      //   if (isCorrect === 1 && isRemind === 1) {
      //     return 'Sudah Dikoreksi'
      //   }
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return 'Koreksi'
      //   }
      // } else {
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return 'Sudah Diingatkan'
      //   }
      //   if (isCorrect === 0 && isRemind === 0) {
      //     return 'Ingatkan'
      //   }
      // }

      // return 'primary'
    },
    iconButton(status, isCorrect, isRemind) {
      let iconBtn = ''
      switch (status) {
        case 1:
          if (isCorrect === 1 && isRemind === 1) iconBtn = 'd-none'
          if ((isCorrect === 0 && isRemind === 1) || (isCorrect === 0 && isRemind === 0)) iconBtn = 'd-none'
          break
        case 0:
          if (isCorrect === 0 && isRemind === 1) iconBtn = mdiBellCheckOutline
          if (isCorrect === 0 && isRemind === 0) iconBtn = mdiBellOutline
          break
        default:
          iconBtn = ''
          break
      }

      return iconBtn
      // if (status === 1) {
      //   if (isCorrect === 1 && isRemind === 1) {
      //     return 'd-none'
      //   }
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return 'd-none'
      //   }
      // } else {
      //   if (isCorrect === 0 && isRemind === 1) {
      //     return mdiBellCheckOutline
      //   }
      //   if (isCorrect === 0 && isRemind === 0) {
      //     return mdiBellOutline
      //   }
      // }

      // return ''
    },
    getColorChip(status) {
      if (status === 1 || typeof status === 'string') return 'v-chip-light-bg success--text'

      return 'v-chip-light-bg error--text'
    },
    remindBtn() {
      this.modalDialog = true
    },
  },
}
</script>

<style>
.v-application.theme--light .v-btn--is-elevated.shadow-none {
  box-shadow: none !important;
  text-transform: capitalize !important;
}
</style>
